






































import { Component, Prop, Vue } from "vue-property-decorator";
import DocAPI from "../../services/api/DocApi";
import { AxiosRequestConfig } from "axios";
@Component
export default class DialogDownload extends Vue {
  @Prop({ default: false }) readonly archive!: boolean;
  @Prop() readonly name!: string;
  @Prop() readonly id!: string;
  private downloadError = false;
  private dialog = false;
  private loading = false;
  async getLink() {
    if (this.archive)
      return await DocAPI.get(`application/${this.id}/documents`);
    else {
      const params = new URLSearchParams();
      params.append("documentId", this.id);
      const config: AxiosRequestConfig = { params };
      return await DocAPI.get("document/download", config);
    }
  }
  onOpenDialog() {
    this.dialog = true;
  }
  onCloseDialog() {
    this.dialog = false;
  }
  onDownload() {
    this.loading = true;
    setTimeout(async () => {
      try {
        const link: any = await this.getLink();
        link.download = this.name;
        document.body.appendChild(link);
        link.click();
        this.downloadError = false;
        setTimeout(() => {
          this.dialog = false;
          this.loading = false;
        }, 200);
      } catch {
        this.downloadError = true;
        this.loading = false;
      }
    });
  }
}
